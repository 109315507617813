.perfo-paper {
  background-color: #d0d0d0;
  margin: 26px 0 24px;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    filter: contrast(0) brightness(1.63);
  }
  &:before {
    top: -24px;
    height: 26px;
    background: url('/img/perfo-paper-top.svg') center center repeat-x;
  }
  &:after {
    bottom: -22px;
    height: 24px;
    background: url('/img/perfo-paper-bottom.svg') center center repeat-x;
  }
}
.perfo-paper--light:extend(.perfo-paper all) {
  background-color: #eeeeee;

  &:before,
  &:after {
    filter: contrast(0%) brightness(1.87);
  }
}
.perfo-paper--dark:extend(.perfo-paper all) {
  background-color: #222222;
  margin-top: 30px;
  margin-bottom: 0;

  &:before,
  &:after {
    filter: contrast(0%) brightness(0.27);
  }
}