// DESKTOP
.info-ticker {
  position: relative;
  margin-top: 6rem;
  @media screen and (min-width: @screen-lg-min) { height: 740px; }
  overflow-y: hidden;
  // background: url('/img/info-box.svg') center center no-repeat;
  padding: 30px 70px 40px;
  border-image-slice: 12 12 12 12 fill;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url('/img/info-box.svg');
}

.info-ticker__heading {
  margin-top: 0;
  font-size: 48px;
}

.info-ticker__heading:before {
  display: block;
  margin-bottom: 26px;
}
.info-ticker--bullseye .info-ticker__heading:before { content: url('/img/icon-bullseye.png'); }
.info-ticker--heart .info-ticker__heading:before { content: url('/img/icon-heart.png'); }

.info-ticker__list {
  font: italic normal 15px/26px "Open Sans", sans-serif;
  margin-top: 4rem;
  padding-left: 0;
  list-style: none;

  li { margin-bottom: 5rem; }
  @media screen and (min-width: @screen-lg-min) {
    li {
      margin-bottom: 0;
      min-height: 170px;
    }
  }

  li:before {
    content: '\2022';
    font: normal 24px/26px "Open Sans", sans-serif;
    vertical-align: -4px;
    position: relative;
    margin-right: 6px;
  }
}

.info-ticker__controls {
  display: none;
  position: absolute;
  top: 32px;
  right: 24px;
}

[class^="info-ticker__control--"] {
  font-size: 3rem;
  width: 37px;
  height: 37px;
  background-color: #f9f8f8;
  text-align: center;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 6px 0 #ddd;
  }
  &:active {
    box-shadow: inset 0 3px 5px #ddd;
  }
}

.info-ticker__control--prev {
  .info-ticker__control-icon {
    vertical-align: 3px;
  }
}
.info-ticker__control--next {
  margin-top: 8px;

  .info-ticker__control-icon {
    vertical-align: 1px;
  }
}

// MOBILE
// nexus 5, galaxy s5, galaxy s6, iphone 5
@media screen and (max-width: 360px) {

  .info-ticker {
    padding: 30px 40px 40px;
    margin-bottom: 0;
  }

  .info-ticker__heading {
    font-size: 36px;
  }
  .info-ticker__heading:before {
    margin-bottom: 0;
  }

  .info-ticker__list {
    list-style: none;
    text-align: justify;
    font-size: 14px;
    line-height: 1.4;

    li + li { margin-top: 3rem; }
  }

}

// nexus 6p, iphone 6+
@media screen and (min-width: 361px) and (max-width: 414px) {

  .info-ticker__heading:before {
    margin-bottom: 0;
  }

  .info-ticker__list {
    padding-left: 2rem;

    li + li { margin-top: 3rem; }
  }

}