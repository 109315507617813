footer.footer {
  padding-top: 2rem;
  padding-bottom: 0;
  z-index: 2; // make sure it's on top of .torn-paper--tuckedIn
}

footer.footer[class*="perfo-paper"] {
  &:before {
    top: -30px;
    height: 30px;
    background: url('/img/perfo-paper-top-2.svg') center center repeat-x;
  }
  &:after {
    display: none;
    background: none;
  }
}

.footer__nav {
  li {
    display: inline-block;
    a:hover,
    a:focus {
      background-color: transparent;
    }
    > a:not(.button-special) {
      color: white;
      font: normal 17px "Open Sans", sans-serif;
      text-transform: uppercase;
      &:hover,
      &:focus {
        color: #f7d971;
      }
    }
    a.button-special {
      color: black;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.footer__contacts {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.footer__contacts-item {
  a {
    display: block;
    margin-bottom: 2rem;
    font: normal 18px/26px "Open Sans", sans-serif;
    color: #fae397;
    @media screen and (min-width: @screen-md-min) { margin-bottom: 0; }
    &:hover,
    &:active,
    &:focus {
      color: white;
      text-decoration: none;
    }
  }
}

.footer__contacts-email:before,
.footer__contacts-phone:before,
.footer__contacts-location:before {
  content: '';
  width: 36px;
  vertical-align: middle;
  display: block;
  margin: 0 auto;
  @media screen and (min-width: @screen-md-min) {
    display: inline-block;
    margin: 0 2.5rem 0 0;
  }
}

.footer__contacts-email:before {
  width: 36px;
  height: 29px;
  background: url('/img/icon-envelope-white.png') center center no-repeat;
}
.footer__contacts-phone:before {
  width: 20px;
  height: 40px;
  background: url('/img/icon-smartphone-white.png') center center no-repeat;
}
.footer__contacts-location:before {
  width: 31px;
  height: 41px;
  background: url('/img/icon-pin-white.png') center center no-repeat;
}

.footer__socials {
  .footer__socials-item {
    display: inline-block;
    a {
      padding: 0;
      display: inline-block;
      vertical-align: bottom;
    }
    a:hover { background-color: transparent; }
  }
  .footer__socials-item + .footer__socials-item { margin-left: 1.5rem; }
  .footer__socials-item--offset-left {
    .footer__socials-item();
    margin-left: .5rem;
  }
}

footer hr {
  border-color: #d0d0d0;
  opacity: .21;
  margin-left: -@grid-gutter-width/2;
  margin-right: -@grid-gutter-width/2;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

footer .meta {
  margin-bottom: 4rem;
}

footer .copyright {
  font: 300 14px "Open Sans", sans-serif;
  color: @gray-lighter;
  margin-bottom: 0;
}

.footer__logo {
  a:after {
    content: '';
    display: inline-block;
    width: 21px;
    height: 21px;
    position: relative;
    top: 4px;
    background: url('/img/OneCreative-logo.png') center center no-repeat;
    transition: all 200ms ease;
  }
  a:hover:after,
  a:active:after,
  a:focus:after {
    background-image: url('/img/OneCreative-logo-hover.png');
  }
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
}