.about-text {
  padding-top: 20px;
  padding-bottom: 50px;
}
.about-director.perfo-paper {
  padding-top: 30px;
  padding-bottom: 50px;
}

.about-text .about-text__heading,
.about-director .about-director__heading {
  margin-top: 0;
  margin-bottom: 40px;
}

.about-page p {
  margin-bottom: 0;
}

.about-page p ~ p {
  margin-top: 25px;
}

.about-director__image {
  margin-bottom: 35px;
}

.about-director__name {
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  margin-top: 40px;
}

.about-director__title {
  font-size: 15px;
  line-height: 26px;
  color: @gray-dark;
}