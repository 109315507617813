.home-main-slider {
  transform: translateY(-20px); // compensate for .torn-paper

  .slider__slide {
    .v-center-flex();
    min-height: 577px;
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
    overflow: hidden;

    p {
      font-weight: 300;
      margin-bottom: 2rem;
    }
    p:last-of-type { margin-bottom: 0; }
  }
  .slider__slide-heading {
    font-size: 5rem;
    color: white;
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .slider__slide-btn {
    margin-top: 4.2rem;
  }
  .slick-prev,
  .slick-next {
    height: 64px;
    line-height: initial;
    
    i {
      font-size: 64px;
      color: white;
    }
  }
  .slick-prev {
    left: 15px;
  }
  .slick-next {
    right: 15px;
  }
}

.slick-track { min-width: 100% }