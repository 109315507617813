#contacts-map {
  height: 580px;
}

.contacts-map[class*="torn-paper"] {
  transform: translateY(-6px);
  
  &:before {
    top: -16px;
  }
}

.contact-details {
  padding-bottom: 80px;
}

.contact-details__list {
  margin-top: 70px;
}

.contact-details__text {}

.contact-details__item {
  .footer__contacts-item();

  a {
    color: black;

    &:hover,
    &:active,
    &:focus {
      color: black;
    }
  }
}

.contact-details-email:before {
  &:extend(.footer__contacts-email:before);
  background: url('/img/icon-envelope-black.png') center center no-repeat;
}
.contact-details-phone:before {
  &:extend(.footer__contacts-phone:before);
  background: url('/img/icon-smartphone-black.png') center center no-repeat;
}
.contact-details-location:before {
  &:extend(.footer__contacts-location:before);
  background: url('/img/icon-pin-black.png') center center no-repeat;
}

.contact-details-email:before,
.contact-details-phone:before,
.contact-details-location:before {
  display: block;
  margin: 0 auto 20px;
}

.contact-details__item-text {
  display: block;
}