// back to top
// https://codyhouse.co/gem/back-to-top/
// .cd-top {
//   display: inline-block;
//   height: 40px;
//   width: 40px;
//   position: fixed;
//   bottom: 40px;
//   right: 10px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
//   /* image replacement properties */
//   overflow: hidden;
//   text-indent: 100%;
//   white-space: nowrap;
//   background: rgba(232, 98, 86, 0.8) url(../img/cd-top-arrow.svg) no-repeat center 50%;
//   visibility: hidden;
//   opacity: 0;
//   -webkit-transition: opacity .3s 0s, visibility 0s .3s;
//   -moz-transition: opacity .3s 0s, visibility 0s .3s;
//   transition: opacity .3s 0s, visibility 0s .3s;
// }
.cd-top {
  display: block;
  background-color: @gray-darker;
  border-radius: @border-radius-small;
  position: fixed;
  top: 460px;
  right: 14px;
  padding: 4px 8px;
  max-width: 30px;
  max-height: 30px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s;

  i {
    font-size: 22px;
    font-weight: bold;
    color: white;
  }
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
  z-index: 2;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5;
}
.no-touch .cd-top:hover {
  background-color: #e86256;
  opacity: 1;
}