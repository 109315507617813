@import "../../../../node_modules/bootstrap-less/bootstrap/bootstrap";
@import "../../../../node_modules/slick-carousel/slick/slick";
@import "../../../../node_modules/slick-carousel/slick/slick-theme";
@import (inline) "../../../../node_modules/lightbox2/dist/css/lightbox.css";
@import (reference) "../../../../node_modules/bootstrap-less/bootstrap/mixins";
@import (reference) "../../../../node_modules/bootstrap-less/bootstrap/variables";
// end of dependencies

@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&subset=cyrillic');

// variables
@grid-float-breakpoint: @screen-md-min;
@font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
@font-family-serif: "Lora", "Times New Roman", Times, serif;
@headings-font-family: 'Lora', serif;
@text-color: black;
@navbar-height: 200px;
@navbar-margin-bottom: 0;
@navbar-default-color: black;
@navbar-default-bg: white;
@nav-link-padding: 10px 1rem;
@slick-font-path: "../fonts/";
@slick-loader-path: "../img/";
@pagination-color: black;
@pagination-border: #ded7bf;
@pagination-hover-color: black;
@pagination-hover-bg: #f9f0d1;
@pagination-hover-border: #ded7bf;
@pagination-active-bg: #c39c16;
@pagination-active-border: #ac8a13;
@pagination-disabled-border: #f5f2e9;

// mixins
@import "mixins/responsive-text-alignment";
@import "mixins/perfo-paper";
@import "mixins/section-heading";

// custom components
@import "components/navbar";
@import "components/footer";
@import "components/back-to-top";
@import "components/slider-home-main";
@import "components/info-box";
@import "components/quote-box";
@import "components/event-listing";
@import "components/news-listing";
@import "components/gallery";
@import "components/partners";

// page-specific
@import "layouts/home";
@import "layouts/about";
@import "layouts/goals";
@import "layouts/contacts";
@import "layouts/events";
@import "layouts/donate";
@import "layouts/news-listing";
@import "layouts/news-single";

// GLOBALS
[class*="col-"].no-padding {
  padding-left: 0;
  padding-right: 0;
}

body > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.no-margin { margin: 0; }
.margin-top--xs { margin-top: 5px; }
.margin-top--sm { margin-top: 10px; }
.margin-top--md { margin-top: 15px; }
.margin-top--lg { margin-top: 20px; }
.margin-bottom--xs { margin-bottom: 5px; }
.margin-bottom--sm { margin-bottom: 10px; }
.margin-bottom--md { margin-bottom: 15px; }
.margin-bottom--lg { margin-bottom: 20px; }

.grayscale {
  filter: grayscale(100%);
}

a { transition: all 200ms ease; }

p {
  font-weight: 100;
  font-size: 15px;
  line-height: 26px;
  // font-family: "Open Sans", sans-serif;
}

// yellow button with funky border
.button-special__offset-bottom { margin-bottom: -16px; }
a.button-special {
  display: inline-block;
  width: 146px;
  font: 600 16px/56px "Open Sans", sans-serif;
  background: url('/img/special_button.svg') center center no-repeat;
  transition: all 200ms ease;
  &:hover,
  &:active,
  &:focus {
    color: black;
    background-image: url('/img/special_button-hover.svg');
  }
}

// regular white button for dark backgrounds
.btn-white {
  font: 600 15px/12px "Open Sans", sans-serif;
  text-transform: uppercase;
  color: black;
  background-color: white;
  border-radius: 3px;
  transition: all 250ms ease;

  padding-top: 18px;
  padding-bottom: 18px;
  &:not(.btn-block) {
    padding-left: 32px;
    padding-right: 32px;
  }

  &:hover,
  &:active,
  &:focus {
    color: black;
    background-color: #f8d970;
  }
}

.btn-dark {
  .btn-white();
  color: white;
  background-color: #222222;
}

// torn paper decorative effect - do not use on container classes
.torn-paper {
  position: relative;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background-repeat: repeat-x;
    z-index: 2;
  }
  &:before {
    background-image: url('/img/white-border-top.svg');
    background-position: center top;
    height: 41px;
    top: 0;
  }
  &:after {
    background-image: url('/img/white-border-bottom.svg');
    background-position: center bottom;
    height: 18px;
    bottom: 0;
  }
  // compensate for navbar
  .navbar-main + & {
    transform: translateY(-20px);
  }
}
.torn-paper--raster {
  .torn-paper();
  &:before {
    background-image: url('/img/white-border-top-smoothed.png');
    height: 41px;
  }
  &:after {
    background-image: url('/img/white-border-bottom-smoothed.png');
    height: 41px;
  }
}
.torn-paper--tuckedIn {
  .torn-paper();
  margin-bottom: -40px;
  padding-bottom: 64px;
}

// floral decorative effect
.deco-floral {
  background: url('/img/deco-floral-2.png') 5% -5% no-repeat, url('/img/deco-floral.png') 95% 100% no-repeat;
}

.hero-image {
  overflow-x: hidden;
}

.slick-prev,
.slick-next {
  z-index: 1;

  &:before {
    content: initial;
  }
}

// vertical centering
.v-center-parent {
  position: relative;
}
.v-center-child {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.v-center-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}