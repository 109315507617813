.quote-box .flex-container {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  .flex-item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
  }
  .flex-item--big {
    flex-grow: 1;
  }
}

.quote-box__avatar {}

.quote-box__text {
  padding-left: 2rem;
  padding-right: 2rem;
}

.quote-box blockquote {
  padding: 0;
  margin: 0;
  border-left: 0;

  footer:before { content: unset; }
}

.quote-box__quote:before {
  content: url('/img/icon-quote.png');
  display: block;
  margin-bottom: 0;
  margin-top: 4rem;

  @media screen and (min-width: @screen-sm-min) {
    margin-bottom: 4rem;
    margin-top: 0;
  }
}

.quote-box__quote {
  font: italic normal 17px/27px "Open Sans", sans-serif;
  color: black;
}

.quote-box__author,
.quote-box__author-desc {
  display: block;
}

.quote-box__author {
  font: bold 24px/1 "Lora", serif;
  text-transform: uppercase;
  margin-top: 3rem;
  color: black;
}

.quote-box__author-desc {
  font: normal 16px/1 "Open Sans", sans-serif;
  margin-top: 2rem;
  color: black;
}