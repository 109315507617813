.news-item__image-overlay {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.news-item__image {
  min-height: 714px;
  transition: all 200ms ease;
}
.news-item__title {
  font-family: @font-family-sans-serif;
  font-size: 46px;
  line-height: 64px;
  margin: 46px auto 30px;
}
.news-item__gallery {
  .v-center-parent();
  margin-top: 50px;
}
.news-item__slider {
  padding-left: 47px;
  padding-right: 47px;
  margin-left: 15px;
  margin-right: 15px;
}
.news-item__gallery-image {
  max-height: 208px;
}
.news-item__control {
  background-color: #e9e8e8;
  width: 47px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;

  .slick-arrow {
    .v-center-child();
    font-size: 56px;
    line-height: 35px;
    color: #5d5d5d;
    
    i {
      padding: 100px 14px;

      a:hover &,
      a:active &,
      a:focus & {
        text-decoration: none;
      }
    }
  }
  &.news-item__prev {
    .text-left();

    &,
    & > .slick-arrow {
      left: 0;
    }
  }

  &.news-item__next {
    .text-right();

    &,
    & > .slick-arrow {
      right: 0;
    }
  }
}
.news-item__gallery-item {
  margin-left: 5px;
  margin-right: 5px;
}
.news-item__date {
  .news-listing__item-content-date();
  margin-top: 0;
  margin-bottom: 38px;
}
.news-item__content {
  p {
    margin-bottom: 0;

    & + p {
      margin-top: 50px;
    }
  }
}
.fb-share-button {
  margin-top: 50px;
  margin-bottom: 20px;
}

.news-item__related {
  background-color: @gray-lighter;
}