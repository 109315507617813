.donate__main {
  padding-bottom: 90px;
}

.donate__heading {
  margin-top: 20px;
  margin-bottom: 50px;
}

.donate__subheading {
  font-size: 30px;
  font-family: @font-family-sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}

.donate__content {
  margin-top: 60px;

  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 45px;
  }
}

.donate__buttons {
  margin-top: 60px;
}