// DESKTOP
// item headers
.event-listing__item-header-date,
.event-listing__item-header-event,
.event-listing__group-heading-value {
  font: normal 20px/1 "Open Sans", sans-serif;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ededed;
}
.event-listing__group-heading {
  & ~ & {
    margin-top: 30px;
  }
}
.event-listing__group-heading-value {
  display: block;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
}

// item content (lower part)
.event-listing__item-content {
  margin-top: 34px;
}

// item details
.event-listing__item-content-details {
  font: 300 15px/25px "Open Sans", sans-serif;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;

    ~ p { margin-top: 20px; }
  }
}

// item heading
.event-listing__item-content-heading {
  font: bold 22px "Open Sans", sans-serif;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;

  .event-listing--with-images & {
    margin-bottom: 60px;
  }
}

// date, time and place
.event-listing__item-content-params {
  padding-left: 0;

  li {
    list-style: none;
    padding-left: 40px;
    background-position: left 4px;
    background-repeat: no-repeat;

    + li { margin-top: 10px; }
  }
  .event-listing__item-content-date b:after {
    content: ', ';
  }
  .event-listing__item-content-date.more-than-month b:after {
    content: '';
  }
  .event-listing--with-images & [data-date-start]:after {
    content: '\2013';
  }
  .event-listing--with-images & .more-than-month [data-date-start]:after {
    content: '\2009\2013\2009';
  }
  .event-listing__start-day-of-week {
    &:after {
      content: '\2009\2013\2009';
    }
  }
  .event-listing__item-content-date {
    background-image: url('/img/icon-calendar.png');
  }
  .event-listing__item-content-time {
    background-image: url('/img/icon-clock.png');
  }
  .event-listing__item-content-place {
    background-image: url('/img/icon-pin-small.png');
    background-position-y: 2px;

    b:not(:empty):after {
      content: '\2009\2013';
    }
  }
}

// text
.event-listing__item-content-text {
  overflow: hidden;
  transition: 200ms ease all;

  .event-listing--with-images & {
    margin-top: 50px;
  }
}

// read more link
.event-listing__item-content-details-read-more {
  a {
    color: black;
    cursor: pointer;
  }
  .glyphicon {
    vertical-align: baseline;
    font-size: 2rem;
  }
  .fa {
    font-size: 22px;
  }
}

// load more link
.event-listing__load-more {
  .event-listing__item-content-details-read-more();
  padding-right: @grid-gutter-width/2;
  
  .glyphicon {
    font-size: 1rem;
  }
}

// calendar widget
.event-listing__item-content-image {
  position: relative;

  .event-listing__item-content-calendar-bg {
    width: 120px;
    height: 118px;
    position: absolute;
    top: 0; right: 0;
    padding: 20px;
    box-sizing: content-box;
  }
  .event-listing__item-content-calendar-bg:before {
    content: '';
    background-color: white;
    opacity: .86;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
  }
}
.event-listing__item-content-calendar {
  border: 4px solid black;
  border-radius: 15px;
  padding: 10px 8px;
  padding-top: 54px;
  margin-top: 8px;
  margin-bottom: 45px;
  position: relative;
  max-width: 155px;

  // image variant
  .event-listing__item-content-image & {
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
  }
}
.event-listing__item-content-calendar-deco {
  display: block;
  position: absolute;
  top: 10px;
  width: 24px;
  height: 24px;
  border: 3px solid black;
  border-radius: 50%;
}
.event-listing__item-content-calendar-deco:before,
.event-listing__item-content-calendar-deco:after {
  content: '';
  display: block;
  background-color: white;
  height: 20px;
  bottom: 16px;

  // image variant
  .event-listing__item-content-image & {
    height: 11px;
    background-color: transparent;
  }
}
.event-listing__item-content-calendar-deco:before {
  border-left: 2px solid black;
  padding-left: 0;
  margin-left: 9px;
  margin-top: -23px;

  // image variant
  .event-listing__item-content-image & {
    margin-top: -14px;
  }
}
.event-listing__item-content-calendar-deco:after {
  border-right: 2px solid black;
  padding-right: 0;
  margin-right: 9px;
  margin-top: -20px;

  // image variant
  .event-listing__item-content-image & {
    margin-top: -11px;
  }
}
.event-listing__item-content-calendar-deco:first-of-type { left: 24px; }
.event-listing__item-content-calendar-deco + .event-listing__item-content-calendar-deco { right: 24px; }

.event-listing__item-content-calendar-days,
.event-listing__item-content-calendar-month {
  display: block;
}

.event-listing__item-content-calendar-days {
  font-weight: 900;
  font-size: 40px;
  line-height: 38px;

  // image variant
  .event-listing__item-content-image & {
    font-size: 28px;
    line-height: 1;
  }
}
.event-listing__item-content-calendar-month {
  font-size: 19px;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 10px;

  // image variant
  .event-listing__item-content-image & {
    font-size: 13px;
    margin-top: 4px;
  }
}
.event-listing__item-content-calendar-days:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background: black;
  border-top: 4px solid black;
  margin-top: -12px;
}
.event-listing__item-content-calendar-end_date {
  &:before {
    content: '\2013';
  }
}
// end calendar widget

// MOBILE
// nexus 5, galaxy s5, galaxy s6, iphone 5
@media screen and (max-width: 360px) {

  // item heading
  .event-listing__item-content-heading {
    font-size: 18px;
  }

  // calendar widget
  .event-listing__item-content-calendar-deco:first-of-type { left: 10px; }
  .event-listing__item-content-calendar-deco + .event-listing__item-content-calendar-deco { right: 10px; }
  .event-listing__item-content-calendar-days {
    font-size: 22px;
    line-height: 1.2;
  }
  .event-listing__item-content-calendar-month {
    font-size: 11px;
    margin-top: 0;
  }
  // end calendar widget

}

// nexus 6p, iphone 6+
@media screen and (min-width: 361px) and (max-width: 414px) {

  .event-listing__item-content-calendar-days { font-size: 38px; }

}