.news-listing__item {
  margin-bottom: 60px;
}
.news-listing__item-content-heading {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 22px;
  font-family: @font-family-sans-serif;
  line-height: 32px;
}
.news-listing__item-content-date {
  margin-top: 15px;
  margin-bottom: 0;
  color: #c39c16;
  font-weight: normal;
  line-height: 24px;
}
.news-listing__item-content-text {
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 100;
  line-height: 25px;
  max-height: 75px;
}
.news-listing__item-content-details-read-more {
  margin-top: 20px;
  margin-bottom: 0;

  a {
    color: black;

    i {
      margin-left: 10px;
      vertical-align: -1px;
    }
  }
}
.news-listing .pagination {
  margin-top: 0;
  margin-bottom: 0;
}