.gallery__item {
  overflow: hidden;
  img {
    filter: grayscale(100%);
    transition: filter 250ms ease;
  }
  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
  &:hover {
    .gallery__hover-text {
      bottom: 0;
    }
  }
}

.gallery__hover-text {
  font: 600 20px/30px "Open Sans", sans-serif;
  color: black;
  text-transform: uppercase;
  padding: 2.5rem;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -150%;
  z-index: 0;
  transition: all 200ms ease-out;
}
.gallery__hover-text:before {
  content: '';
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: white;
  opacity: .53;
  z-index: -1;
}