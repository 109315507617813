.home-page {
  .quote-box {
    margin-top: 6rem;
    @media screen and (min-width: @screen-md-min) { margin-top: 14rem; }

    .flex-container {
      max-width: 800px;
    }
  }
  .event-listing { padding-bottom: 6rem; }
  .gallery {
    background-color: @gray-lighter;
    padding-bottom: 7rem;
  }
  .partners {
    padding-bottom: 8rem;
  }
}