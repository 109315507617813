.partners .flex-container {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;

  .flex-item {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 130px;
  }
  .flex-item--bottom {
    .flex-item();
    align-self: flex-end;
  }
}

.partners {
  padding-left: 45px;
  padding-right: 45px;
}

.partners-slider {
  .slick-prev,
  .slick-next {
    height: 64px;
    line-height: initial;
    
    i {
      font-size: 64px;
      color: black;
    }
  }
}

.partners__item {
  &.slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
  }
  img {
    filter: grayscale(100%);
    transition: filter 250ms ease;
  }
  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
}

.partners__item--special {
  .partners__item();
  img {
    filter: grayscale(100%) opacity(50%);
    transition: filter 250ms ease;
  }
  &:hover {
    img {
      filter: grayscale(0%) opacity(100%);
    }
  }
}