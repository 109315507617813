// navbar element (top-most ancestor)
.navbar-main {
  border: 0;
}

// navbar content (.container)
.navbar-main__content {
  position: relative;
  z-index: 3; // to make sure navbar content appears on top of the .torn-paper decoration (z-index 2)
}

// flexbox nav element (desktop)
.main-navigation {
  height: @navbar-height - 30;
}

// flexbox centered logo
.main-navigation.flex-container {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-end;

  .flex-item {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
  .flex-big {
    flex-grow: 2;
  }
}
// end flexbox centered logo

.navbar-main__logo-mobile {
  margin-top: ceil((@navbar-height - 143) / 2);
  margin-left: @grid-gutter-width/2;
}

// links style
.main-navigation a {
  font: normal 16px "Open Sans", sans-serif;
  text-transform: uppercase;
  color: black;
  &:hover,
  &:active,
  &:focus {
    color: #c39c16;
    text-decoration: none;
  }
}