.section-heading {
  font-family: @headings-font-family;
  font-weight: normal;
  font-size: 48px;
  position: relative;
  display: inline-block;
  margin-top: 70px;
  margin-bottom: 60px;
  
  @media screen and (min-width: @screen-sm-min) {
    &:before,
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid black;
      width: 100px;
      height: 1px;
      position: absolute;
      top: 50%;
    }
    &:before {
      left: 0;
      transform: translateX(-125%);
    }
    &:after {
      right: 0;
      transform: translateX(125%);
    }
  }
}
.section-heading--small:extend(.section-heading all) {
  font-size: 38px;

  [class*="torn-paper"] & {
    margin-top: 70px;
    margin-bottom: 40px;
  }
}