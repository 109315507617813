.goals {
  padding-top: 50px;

  .goals__heading span:before {
    background-image: url('/img/icon-bullseye.png');
    vertical-align: -7px;
  }
}
.activities {
  background-color: @gray-lighter;
  padding-top: 80px;

  .activities__heading span:before {
    background-image: url('/img/icon-heart.png');
    vertical-align: -10px;
  }
}

.goals .goals__heading,
.activities .activities__heading {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 38px;

  & span:before {
    content: '';
    display: inline-block;
    width: 38px;
    height: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 2rem;
  }
}

.activities.torn-paper {
  margin-bottom: -34px;
  padding-bottom: 58px;
}
.activities.torn-paper:after {
  background-image: none;
}

.goals__list-column,
.activities__list-column {
  padding-left: 40px;
}

.goals__list-item,
.activities__list-item {
  margin-bottom: 40px;
}
.goals__list-item:before,
.activities__list-item:before {
  content: '\2022';
  font-size: 24px;
  vertical-align: bottom;
  margin-left: -18px;
  margin-right: 1rem;
}